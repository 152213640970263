<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
// import branchComponent from "@/components/branchComponent.vue";

// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "ใบแจ้งหนี้",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      // tableData: tableData,

      title: "ใบแจ้งหนี้",
      items: [
        {
          text: "การเงิน",
          active: true,
        },
        {
          text: "ใบแจ้งหนี้",
          active: false,
        },
      ],
      rowsSelect: [],
      loading: false,
      chk: "",
      selected: "",
      rowsTotalpages: "",
      rowsperPage: "",
      rowsPage: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      arrTest: [],
      currentPageCreate: 1,
      perPageCreate: 10,
      branchMaster: [],
      rowsRo: [],
      totalRecord: "",
      totalRecords: 0,
      pageOptions: [10, 25, 50, 100],
      isHidden: false,
      payment: "",
      branch: "",
      type: "",
      roId: "",
      invId: "",
      serviceRo: [],
      // filterCreate: {
      //   branch: "",
      //   vin: "",
      //   licensePlate: "",
      // },
      selectMode: "single",
      userBranchId: "",
      // receipt: [
      //   {
      //     id: 1,
      //     receiptName: "ออกใบกำกับภาษีปกติ",
      //   },
      //   {
      //     id: 2,
      //     receiptName: "ออกใบกำกับภาษีเคลม Warranty",
      //   },
      // ],
      pay: [
        {
          id: 1,
          payName: "ชำระเงินสด",
        },
        {
          id: 2,
          payName: "เครดิต",
        },
      ],
      filter: {
        roCode: "",
        customer: "",
        invCode: "",
        licensePlate: "",
        userBranchId: "",
      },
      optionBranch: [],
      userBranch: "",
      urlPrint: "",
      filterOn: [],
      rows: [],
      rowsCreate: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "index",
          label: "ลำดับ",
          sortable: false,
        },
        {
          key: "invCode",
          sortable: true,
          label: "เลขที่ใบแจ้งหนี้",
        },
        {
          key: "roCode",
          sortable: true,
          label: "เลขใบแจ้งซ่อม",
        },
        {
          key: "customerNameTh",
          sortable: true,
          label: "ชื่อ",
        },
        {
          key: "customerFamilyNameTh",
          sortable: true,
          label: "สกุล",
        },
        {
          key: "licensePlate",
          sortable: true,
          label: "ป้ายทะเบียน",
        },
        {
          key: "invDate",
          sortable: true,
          label: "วันที่สร้าง",
        },
        {
          key: "grandTotalPrice",
          sortable: true,
          label: "ยอดรวม",
          class: "text-end",
        },
        {
          key: "status",
          sortable: true,
          label: "สถานะ",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
        {
          key: "action",
          sortable: false,
          label: appConfig.action,
        },
      ],
      // fieldsCreate: [
      //   {
      //     key: "index",
      //     label: "ลำดับ",
      //     sortable: false,
      //   },
      //   {
      //     key: "roCode",
      //     sortable: true,
      //     label: "รหัสใบซ่อม",
      //   },
      //   {
      //     key: "customerFullNameTh",
      //     sortable: true,
      //     label: "ชื่อ-สกุล",
      //   },
      //   {
      //     key: "licensePlate",
      //     sortable: true,
      //     label: "ป้ายทะเบียน",
      //   },
      //   {
      //     key: "vehicleBrandEn",
      //     sortable: true,
      //     label: "ยี่ห้อ",
      //   },
      //   {
      //     key: "vehicleModelEn",
      //     sortable: true,
      //     label: "รุ่น",
      //   },
      //   {
      //     key: "roDate",
      //     sortable: true,
      //     label: "วันที่สั่งซ่อม",
      //   },
      //   {
      //     key: "grandTotalPrice",
      //     sortable: true,
      //     label: "ยอดรวม",
      //   },
      //   {
      //     key: "branchName",
      //     sortable: true,
      //     label: "สาขา",
      //   },
      // ],
      overlayFlag: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    // rows() {
    //   return this.rows.length;
    // },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });
    this.optionBranch = user.userAccessBranch;
    this.userBranchId = user.branchId;
    this.userBranch = arrayBranch;
    this.totalRows = this.rows.total;
    this.getData();
    // this.getDataCreate();
  },
  created() {
    // this.select()
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    getData: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/invoice", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            "branchId[]":
              this.filter.userBranchId.branchId != null
                ? this.filter.userBranchId.branchId
                : this.userBranchId,
            year: this.year,
            month: this.month,
            invCode: this.filter.invCode,
            status: this.status,
            roCode: this.filter.roCode,
            licensePlate: this.filter.licensePlate,
            customer: this.filter.customer,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.rows.total = response.data.total;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          // this.isHidden = true;
        });
    },
    // getDataCreate: function() {
    //   this.loading = true;
    //   useNetw
    //     .get("api/invoice/repair-orders", {
    //       params: {
    //         page: this.currentPageCreate,
    //         perPage: this.perPageCreate,
    //         branchId: this.filterCreate.branch.branchId != null ? this.filterCreate.branch.branchId : this.userBranchId,
    //       },
    //     })
    //     .then((response) => {
    //       this.rowsCreate = response.data.data;
    //       // this.rowsCreate.total = response.data.total;
    //       this.rowsCreate.total = response.data.from;
    //       this.totalRecord = response.data.total;
    //     })
    //     .catch((err) => {
    //       Swal.fire(
    //         appConfig.swal.title.error,
    //         JSON.stringify(err.response.data.message),
    //         appConfig.swal.type.error
    //       );
    //     })
    //     .then(() => {
    //       this.loading = false;
    //       this.isHidden = true;
    //     });
    // },
    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },
    // handleChangePageCreate(page) {
    //   this.currentPageCreate = page;
    //   this.getDataCreate();
    // },
    // handlePageChangeCreate(active) {
    //   this.perPageCreate = active;
    //   this.currentPageCreate = 1;
    //   this.getDataCreate();
    // },
    onRowSelected(rows) {
      this.selected = rows[0];
      this.alertConfirm(this.selected.roId);
      // this.dataRo.roId = null
      // this.resetModal();
      // this.showRo();
    },
    alertConfirm(roId) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการออกใบแจ้งหนี้หรือไม่ !!?",
          // text: "ต้องการออกใบกำกับภาษีหรือไม่ !!",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.confirmSubmitInvoice(roId);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "ยกเลิกเรียบร้อยแล้ว",
              "error"
            );
          }
        });
    },
    confirmSubmitInvoice: function(roId) {
      this.roId = roId;
      useNetw
        .post("api/invoice/store", {
          roId: this.roId,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({
            name: "detail-invoiceId",
            params: { invId: response.data.invId },
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
        });
    },
    // selectAllRows() {
    //   this.$refs.selectableTable.selectAllRows();
    // },
    // clearSelected() {
    //   this.$refs.selectableTable.clearSelected();
    // },
    deleteTaxInv(invId) {
      this.invId = invId;
      this.overlayFlag = true;
      useNetw
        .delete("api/invoice/delete", {
          data: {
            invId: this.invId,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getData();
          // this.getDataCreate();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    handleSearch() {
      this.getData();
    },
    // showRo() {
    //   this.getDataRo();
    // },
    alertDelete(invId) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบใบแจ้งหนี้หรือไม่ !!?",
          // text: "ต้องการออกใบกำกับภาษีหรือไม่ !!",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.deleteTaxInv(invId);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "ยกเลิกเรียบร้อยแล้ว",
              "error"
            );
          }
        });
    },
    getInvoicePrint(invId) {
      this.invId = invId;
      this.loading = true;
      useNetw
        .get("api/invoice/print", {
          params: {
            invId: this.invId,
          },
        })
        .then((response) => {
          this.urlPrint = response.data.url;
          // window.open(this.urlPrint);
          window.location = (this.urlPrint);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  middleware: "authentication",
};
</script>
<style>
.modal-custom .modal-dialog {
  max-width: 95%;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                  <div class="row">
                    <!-- <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3">
                        ออกใบกำกับภาษี:
                        <multiselect
                          v-model="type"
                          label="receiptName"
                          type="search"
                          :options="receipt"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div> -->
                    <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3 position-relative">
                        สาขา:
                        <multiselect
                          v-model="filter.userBranchId"
                          label="nameTh"
                          type="search"
                          :options="optionBranch"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3">
                        เลขที่ใบแจ้งซ่อม:
                        <b-form-input
                          v-model="filter.roCode"
                          type="search"
                          class="form-control"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3">
                        ป้ายทะเบียน:
                        <b-form-input
                          v-model="filter.licensePlate"
                          type="search"
                          class="form-control"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3">
                        ชื่อลูกค้า:
                        <b-form-input
                          v-model="filter.customer"
                          type="search"
                          class="form-control"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3">
                        เลขที่ใบแจ้งหนี้:
                        <b-form-input
                          v-model="filter.invCode"
                          type="search"
                          class="form-control"
                        ></b-form-input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                  <b-button
                    class="btn float-end m-1"
                    variant="info"
                    type="submit"
                    @click="handleSearch"
                  >
                    <i class="uil-search-alt"></i>
                    ค้นหา
                  </b-button>
                  <b-button
                    class="btn float-end m-1"
                    variant="primary"
                    :to="{
                      name: 'create-invoice',
                    }"
                  >
                    <i class="uil-plus"></i>
                    สร้าง
                  </b-button>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-sm-12 col-md-3">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      แสดงผล&nbsp;
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        @input="handlePageChange"
                      ></b-form-select
                      >&nbsp;รายการ
                    </label>
                  </div>
                </div>
              </div>
              <b-skeleton-wrapper :loading="loading">
                <!-- skeleton  -->
                <template #loading>
                  <b-skeleton-table></b-skeleton-table>
                </template>
                <div class="table-responsive mb-0">
                  <b-table
                    :items="rows"
                    :fields="fields"
                    :current-page="1"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    responsive="sm"
                  >
                    <template #cell(index)="rows">
                      {{ rows.index + 1 }}
                    </template>
                    <template #cell(status)="rowRo">
                      <span
                        class="badge bg-warning font-size-14"
                        v-if="rowRo.item.status === 'DFT'"
                      >
                        ร่าง
                      </span>
                      <span
                        class="badge bg-info font-size-14"
                        v-if="rowRo.item.status === 'SUBMIT'"
                      >
                        รออนุมัติ
                      </span>
                      <span
                        class="badge bg-success font-size-14"
                        v-if="rowRo.item.status === 'APV'"
                      >
                        ยืนยันออกใบกำกับ
                      </span>
                      <span
                        class="badge bg-danger font-size-14"
                        v-if="rowRo.item.status === 'CAN'"
                      >
                        ยกเลิก
                      </span>
                    </template>
                    <template v-slot:cell(action)="rowsRo">
                      <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                          <router-link
                            href="javascript:void(0);"
                            class="px-2 text-primary"
                            v-b-tooltip.hover
                            :to="{
                              name: 'detail-invoiceId',
                              params: { invId: rowsRo.item.invId },
                            }"
                            title="View"
                          >
                            <i class="uil uil-pen font-size-18"></i>
                          </router-link>
                        </li>
                        <li class="list-inline-item">
                          <a
                            href="javascript:void(0);"
                            class="px-2 text-danger"
                            v-b-tooltip.hover
                            title="Delete"
                            @click="alertDelete(rowsRo.item.invId)"
                          >
                            <i class="uil uil-trash-alt font-size-18"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="javascript:void(0);"
                            class="px-2 text-success"
                            v-b-tooltip.hover
                            title="Print"
                            @click="getInvoicePrint(rowsRo.item.invId)"
                          >
                            <i class="uil-print font-size-18"></i>
                          </a>
                        </li>
                      </ul>
                    </template>
                  </b-table>
                </div>
              </b-skeleton-wrapper>
              <div class="row">
                <div class="col">
                  <div class="align-items-center">
                    แสดงผล {{ perPage }} หน้า {{ currentPage }} จาก
                    {{ this.rows.total }} รายการ
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="rows.total"
                        :per-page="perPage"
                        @change="handleChangePage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>

    <!-- <b-modal
      ref="modalCreateInvoice"
      id="modalCreateInvoice"
      title="ค้นหารายการใบแจ้งหนี้"
      hide-footer
      centered
      size="xl"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 align-items-center">
            <div class="row align-items-center">
              <div class="col-12 col-sm-6 col-md-4">
                <div class="mb-3">
                  สาขา:
                  <multiselect
                    v-model="filterCreate.branch"
                    label="nameTh"
                    type="search"
                    :options="optionBranch"
                    :show-labels="false"
                    open-direction="bottom"
                    placeholder=""
                  >
                    <span slot="noResult">ไม่พบข้อมูล</span>
                  </multiselect>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4">
                <div class="mb-3">
                  เลขที่ใบแจ้งซ่อม:
                  <b-form-input
                    v-model="filterCreate.vin"
                    type="search"
                    class="form-control"
                  ></b-form-input>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4">
                <div class="mb-3">
                  เลขใบแจ้งหนี้:
                  <b-form-input
                    v-model="filterCreate.licensePlate"
                    type="search"
                    class="form-control"
                  ></b-form-input>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4">
                <div class="mb-3">
                  ชื่อลูกค้า:
                  <b-form-input
                    v-model="filterCreate.vin"
                    type="search"
                    class="form-control"
                  ></b-form-input>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4">
                <div class="mb-3">
                  เลขที่ใบกำกับภาษี:
                  <b-form-input
                    v-model="filterCreate.licensePlate"
                    type="search"
                    class="form-control"
                  ></b-form-input>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12">
            <b-button class="btn float-end" variant="info" type="submit">
              <i class="uil-search-alt"></i>
              ค้นหา
            </b-button>
          </div>
        </div>
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton-table
              :rows="5"
              :columns="6"
              :table-props="{ bordered: false, striped: true }"
              animation="throb"
            ></b-skeleton-table>
          </template>
          <div class="row mt-2">
            <div class="col-sm-12 col-md-12">
              <div class="row align-items-center">
                <div class="col-sm-5 col-md-3">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-block align-items-center">
                      แสดงผล
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        @input="handlePageChangeCreate"
                      ></b-form-select
                      >&nbsp; รายการ
                    </label>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6"></div>
              </div>
            </div>
          </div>
          <div class="table-responsive mb-0">
             <b-table
              :items="rowsCreate"
              :fields="fieldsCreate"
              responsive="true"
              :per-page="perPage"
              :current-page="1"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              ref="selectableTable"
              selectable
              :select-mode="selectMode"
              @row-selected="onRowSelected"
            >
              <template #cell(index)="rowsCreate">
                {{ rowsCreate.index + 1 }}
              </template>
            </b-table>
          </div>

          <div class="row">
            <div class="col">
              หน้า {{ currentPageCreate }} จาก {{ this.rowsCreate.total }} ทั้งหมด
              {{ this.totalRecord }} รายการ
            </div>

            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                  <b-pagination
                    v-model="currentPageCreate"
                    :total-rows="totalRecord"
                    :per-page="perPageCreate"
                    @change="handleChangePageCreate"
                  ></b-pagination>
                </ul>
              </div>
            </div>
            <br />
            <hr />
            <br />
          </div>
        </b-skeleton-wrapper>
      </div>
    </b-modal> -->
  </Layout>
</template>
